.key-fig-title {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
  color: var(--color-primary);
}
.key-fig-gray-title {
  color: #777777;
}
.key-figure p {
  width: 100%;
  color: #000;
}
.about-headline {
  color: var(--color-primary);
}

.key-figures-container {
  display: flex;
  margin: 15rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  max-width: 1296px;
  width: 90%;
}

@media screen and (min-width: 844px) and (max-width: 1150px) {
  .key-figures-container {
    width: 90%;
  }
}
@media screen and (min-width: 768px) and (max-width: 845px) {
  .key-figures-container {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .key-figures-container {
    margin: 2rem 1rem;
    align-items: center;
  }
}
@media screen and (min-width: 450px) and (max-width: 767px) {
  .key-figures-container {
    flex-direction: column;
    gap: 2rem;
    width: 50%;
  }
}
@media screen and (max-width: 449px) {
  .key-figures-container {
    flex-direction: column;
    gap: 2rem;
    width: 70%;
  }
}
/* Key Figures Update - Feb- 2023*/

.key-fig-container {
  background-image: url(../../../Images/KeyFigBg.png);
  display: flex;
  justify-content: center;
}

.key-figure {
  border-right: 1px solid #ececec;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 3rem;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .key-figure {
    border-right: none;
    border-bottom: 1px solid #ececec;
    padding: 1rem 3rem;
  }
}

.key-figure-4 {
  border-right: none;
  border-bottom: none;
}
.key-fig {
  font-size: 7rem;
  font-family: "Raleway", sans-serif;
  color: #cd151d;
}
.key-figure p {
  color: black;
}
@media screen and (min-width: 1025px) and (max-width: 1280px) {

  .key-fig {
    font-size: 5rem;
  }
}
@media screen and (min-width: 945px) and (max-width: 1024px) {

  .key-fig {
    font-size: 5rem;
  }
  .key-figures-container {
    gap: 2rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 944px) {
  .key-fig {
    font-size: 4rem;
  }
  .key-figures-container {
    gap: 2rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 944px) {
  
  .key-fig-header {
    font-size: 1.8rem;
  }
  .key-fig-headline {
    font-size: 2.5rem;
  }

  .key-fig-title {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.key-fig-unit {
  font-size: 1.6rem;
  font-weight: 400;
  color: #cd151d;
  margin: 0;
}

@media screen and (min-width: 481px) and (max-width: 819px) {
  
  .key-fig {
    font-size: 4rem;
  }

  .key-fig-sub-title,
  .key-fig-sub-headline {
    text-align: left;
  }
  .key-fig-sub-title {
    font-size: 3rem;
  }
  .key-fig-title {
    font-size: 3.5rem;
  }
  .key-fig-sub-headline {
    text-align: left;
    margin: 0;
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .key-figures-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .key-fig-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .key-fig-header {
    font-size: 1.6rem;
  }
  .key-fig-headline {
    font-size: 2rem;
  }
  .key-fig-title {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 3rem;
  }
  .key-fig-sub-title {
    font-size: 2.5rem;
    text-align: left;
  }

  .key-fig-sub-headline {
    text-align: left;
    margin: 0;
    width: 100%;
  }

  .key-fig {
    font-size: 3.5rem;
  }
}
