.contact-box-container {
  display: flex;
  justify-content: center;
  margin: 0 5%;
  margin-top: 5rem;
  top: -85%;

  .contact-box {
    background-color: rgb(13, 51, 101, 0.15);
    border-radius: 20px;
    box-shadow: 0 6px 20px grey;
    width: 45rem;
    height: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1.5rem 1%;
    padding: 30rem, 0;

    .contact-box-icon {
      width: 4rem;
      margin-bottom: 5%;

      @media screen and (max-width: 1280px) {
        width: 3rem;
      }
    }

    .contact-box-content {
      font-size: 2rem;
      color: black;
      width: 90%;
      text-align: center;

      @media screen and (max-width: 1280px) {
        width: 95%;
        font-size: 2rem;
      }
      @media (max-width: 480px) {
        font-size: 1.6rem;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .contact-box-container {
    flex-wrap: wrap;
  }
}

.contact-form-map {
  width: 100%;
  min-height: 60rem;
  display: flex;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
  margin-top: 3%;
  background-color: #f3f3f3;
  .contact-form-container {
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    width: 50%;
    @media (max-width: 1024px) {
      width: 100%;
    }
    //padding: 5%;
    .contact-form-card {
      width: 100%;
      border-radius: 20px;
      padding: 5% 0;
    }
    .contact-form-header {
      width: 80%;
      margin-bottom: 10px;
      @media (max-width: 600px){
        width: 90%;
      }
      margin: auto;
      @media (max-width: 767px) {
        h3 {
          font-size: 22px;
        }
        p {
          font-size: 16px;
        }
      }
      @media (max-width: 480px) {
        h3 {
          font-size: 18px;
        }
        p {
          font-size: 16px;
        }
      }
      padding: 3%;
      h3,
      p {
        color: #000;
        margin-bottom: 10px;
        width: 100%;
      }
    }
    form {
      display: flex;
      flex-direction: column;
      //padding-right: 30px;
      align-items: center;
      width: 80%;
      @media (max-width: 600px){
        width: 90%;
      }
      margin: auto;
      input {
        height: 25px;
      }
      textarea {
        height: 100px;
      }
      input,
      textarea {
        border: 0;
        background-color: #e4e4e4;
        padding: 10px;
        width: 95%;
        border-radius: 3px;
        min-width: 250px;
        @media (max-width: 480px) {
          margin-left: 0;
        }
        margin-bottom: 20px;
      }
      .form-input-row {
        display: flex;
        align-items: flex-start;
        width: 100%;
        h4 {
          width: 20%;
          min-width: 80px;
        }
      }
    }
  }
.submit-wrapper{
  width: 80%;
  margin: auto;
  >*{
    font-size: 14px;
  }
}
  /*************      Google map intgrated using iframe      ****************/

  .contact-map {
    width: 50%;
    @media (max-width: 1024px) {
      width: 100%;
      height: 60vh;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

// Contact Cards Update
// .contact-card-container{
//   background-image: url(../images/Global-Map-op.png);

// }

/*********************************************************/

/* Contact Cards*/

.contact-locations {
  max-width: 1296px;
  width: 90%;
 // display: flex;
  justify-content: flex-start;
  //align-items: center;
  padding: 5rem 0;
  @media screen and (max-width: 475px) {
    padding: 5rem 0;
    width: 100%;
  }
  margin-bottom: 30px;
}

.contact-locations h3 {
  color: #000;
  //text-align: center;
  width: 80%;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  font-size: 3rem;
  @media screen and (min-width: 760px) and (max-width: 1024px) {
    font-size: 2.6rem;
  }
  @media screen and (max-width: 759px) {
    font-size: 2.2rem;
  }
}

.contact-header-gray {
  color: #777777;
}
.contact-text {
  color: #222;
  padding-top: 15px;
}
.contact-card-container {
  margin: 1rem;
  max-width: 1296px;
  width: 90%;
  margin: auto;
}

.card-wrapper {
  display: flex;
  justify-content: stretch;
  @media screen and (max-width: 760px) {
    justify-content: center;
  }
  width: 100%;
}
.contact-card-row1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media screen and (min-width: 761px) and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 760px) {
    grid-template-columns: repeat(1, 1fr);
  }

  //width: 90%;
  // @media screen and (min-width: 550px) and (max-width: 760px) {
  //   width: 70%;
  // }
  // @media screen and (min-width: 1326px) {
  //   width: 80%;
  // }
  // margin: auto;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  row-gap: 30px;
  @media screen and (min-width: 1201px) and (max-width: 1325px) {
    gap: 20px;
  }
  margin-bottom: 50px;
}
.contact-card {
  background-color: #f6f6f6;

  justify-content: center;
  //width: 100%;
  width: 400px;
  @media screen and (min-width: 1326px) {
    min-width: 400px;
    width: 100%;
  }
  height: 300px;
  @media screen and (min-width: 1201px) and (max-width: 1325px) {
    width: 360px;
    height: 280px;
  }
  @media screen and (min-width: 761px) and (max-width: 1200px) {
    width: 100%;
  }
  @media screen and (min-width: 760px) and (max-width: 900px) {
    width: 350px;
    height: 250px;
  }
  @media screen and (min-width: 370px) and (max-width: 760px) {
    width: 100%;
    height: 100%;
    // min-width: 250px;
  }
  @media screen and (max-width: 369px) {
    width: 100%;
    min-width: 280px;
  }
}
#card1-border {
  border-top: 5px solid #000;
}
#card2-border {
  border-top: 5px solid #007fff;
}
#card3-border {
  border-top: 5px solid #df2401;
}
#card4-border {
  border-top: 5px solid #f77f00;
}
#card5-border {
  border-top: 5px solid #fcd116;
}
#card6-border {
  border-top: 5px solid #008750;
}
#card7-border {
  border-top: 5px solid #007168;
}
#card8-border {
  border-top: 5px solid #080077;
}
#card9-border {
  border-top: 5px solid #003580;
}
#card10-border {
  border-top: 5px solid #727272;
}
.card-content {
  padding: 2rem 1.5rem;
  >*{
    svg {
      width: 25px;
      height: 15px;
    }
  }
}
.card-content h4 {
  font-size: 2rem;
  padding-bottom: 1rem;
  text-transform: uppercase;
}
.card-content .company-name {
  font-size: 1.8rem;
  color: #1e2019;
  padding-bottom: 1rem;
}
.card-content p {
  font-size: 1.8rem;
  @media  (max-width: 1200px) {
    font-size: 1.6rem;
  }
  @media screen and (max-width: 650px) {
    font-size: 1.4rem;
  }
  color: #727272;
  margin-bottom: 0;
}

.fa-phone:before {
  width: 20px;
  height: 20px;
}
.phone {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
}
.email {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
}
.contact-person {
  display: flex;
  gap: 1rem;
  padding-bottom: 1rem;
}
.company-no-name {
  opacity: 0;
}
.card-content .fa-color {
  color: #083e89;
  margin-right: 0;
}


// .flag-icon{
//   display: flex;
//   align-items: center;
// }
.flag-country-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.contact-card-hr {
  margin: 1rem 0;
  opacity: 0.25;
}
