

.key-figures {
    margin-top: 10rem ;
    display: flex;
    flex-direction: column;
    max-width: 1296px;
    width: 90%;
    align-items: center; 
    justify-content: center;
    margin: auto;
    padding: 8% 0;
    @media (max-width: 600px){
      padding: 12% 0;
    }
  }
  
  .key-figures-wrappper{
    display: flex; 
    //width: 80%;
    gap: 40px;
    /* margin-bottom: 5rem; */
  }
  
  .key-fig-header {
    /* padding-bottom: 20rem; */
    width: 60%;
    color: white;
    font-size: 2.2rem;
  }
  
  .key-fig-header-image{
    width: 40%;
    display: flex;
    justify-content: flex-end;
  
  }
  @media screen and (min-width:1025px) and (max-width: 1200px){
    .key-figures-wrappper{
      width: 100%;
    }
  }
  @media screen and (min-width:550px) and (max-width: 767px){
    .key-fig-header{
      width: 100%;
    }
    .key-fig-header-image{
      width: 100%;
    
    }
  }
  .key-fig-header-image img{
     width: 100%;
     object-fit: cover;
     object-position: center;
  }
  @media screen and (min-width:1650px){
    .key-fig-header-image img{
      width: 90%;
    }
  }
  @media screen and (min-width:481px) and (max-width: 767px){
    .key-figures-wrappper{
      flex-direction: column-reverse;
    }
  }
  @media screen and (max-width: 549px) {
    .key-figures-wrappper{
      flex-direction: column-reverse;
      width: 100%;
    }
    .key-fig-header{
      width: 100%;
    }
    .key-fig-header-image{
      width: 100%;
    }
   
    }
    @media screen and (min-width:768px) and (max-width: 1024px){
      .key-figures-wrappper{
        width: 100%;
      }
    }
  
  .key-fig-sub-title {
    color: #083e89;
    padding-bottom: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .key-fig-headline {
    font-size: 3.5rem;
    text-align: center;
  }
  .key-fig-sub-headline{
     color: #222;
  }
  
  .key-fig-headline span {
    font-weight: bold;
  }