:root {
  --heights: 600px;
  --widths: 100%;
}
// A container containing the whole
.slider-container {
  height: var(--heights);
  @media (max-width: 1024px) {
    height: 480px;
  }
  @media screen and (max-width: 600px) {
    height: 400px;
  }
  @media (max-width: 400px) {
    height: 300px;
  }
  width: var(--widths);
  position: relative;
  margin: auto;
  margin-top: 63px;
  overflow: hidden;
  z-index: 1;
}
.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.slides {
  height: var(--heights);
  width: var(--widths);
  @media (max-width: 1024px) {
    height: 480px;
  }
  @media screen and (max-width: 600px) {
    height: 400px;
  }
  @media (max-width: 400px) {
    height: 300px;
  }
  position: relative;
 
}

.slide-image {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  // background-size: cover;
  // background-position: center;
  object-position: center;
   animation-name: fadeIn;
    animation-duration: 3s;

}

@keyframes fadeIn {
  0% { opacity: 0.3; }
  100% { opacity: 1; }
}

.slider-headline-body {
  display: flex;
  justify-content: center;
}

// Hero Content

.hero-content {
  background: rgb(0, 0, 0); //creating black gradient overlay
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.8015581232492998) 0%,
    rgba(0, 0, 0, 0.7595413165266106) 10%,
    rgba(0, 0, 0, 0.7455357142857143) 25%,
    rgba(0, 0, 0, 0.4766281512605042) 45%,
    rgba(0, 0, 0, 0) 100%
  );
  //backdrop-filter: blur(10px);            // To create glass effect
  width: 100%;
  height: 600px;
  @media (max-width: 1024px) {
    height: 480px;
  }
  @media screen and (max-width: 600px) {
    height: 400px;
  }
  @media (max-width: 400px) {
    height: 300px;
  }
  border: 1px;
}
.slider-headline-body {
  display: flex;
  max-width: 1296px;
  width: 90%;
  margin: auto;
}

.slider-headline-body .hero-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  margin: auto;
 // margin-top: 63px;
  justify-content: center;
  align-items: center;
  transform: translate(0%, -50%);
  top: 50%;
  left: 0;
  text-align: start;
  z-index: 10;
}
.first-title {
  color: #fff;
  width: 70%;
  @media screen and (max-width: 768px) {
    width: 85%;
  }
  @media (min-width: 600px) and (max-width: 625px) {
    width: 90%;
  }
  @media (max-width: 440px) {
    width: 100%;
  }
  margin-bottom: 35px !important;
  line-height: normal;
  text-transform: uppercase;
}
.home-hero-intro-text {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  padding-top: 0.5rem;
  letter-spacing: 5px;
}
.home-hero-text {
  color: #ececec;
  margin-bottom: 5rem;
  font-size: 2rem;

  @media screen and (max-width: 591px) {
    width: 90%;
  }
  @media screen and (min-width: 1024px) and (max-width: 1200px) {
    font-size: 1.8rem;
  }
  @media screen and (min-width: 491px) and (max-width: 1023px) {
    font-size: 1.8rem;
  }
  @media screen and (max-width: 490px) {
    font-size: 1.6rem;
  }
}

.hero-content .btn1 {
  margin-right: 2rem;
  white-space: nowrap;
  margin-bottom: 2rem;
  @media screen and (max-width: 360px) {
    .btn1 a {
      text-align: center;
      padding-left: 3rem;
      align-items: center;
    }
  }
}
@media screen and (max-width: 360px) {
  .hero-btn .btn1 {
    margin-right: 1rem;
    padding: 6px 18px;
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 361px) and (max-width: 670px) {
  .hero-btn .btn1 {
    margin-right: 1rem;
    padding: 8px 22px;
    font-size: 1.4rem;
  }
}

// Next and Previous Paginations

// Dots

.all-dots {
  width: 100%;
  position: absolute;
  display: flex;
  top: 90%;
  justify-content: center;
  z-index: 200;
}

.dot {
  cursor: pointer;
  height: 1.2rem;
  width: 1.2rem;
  margin: 0px 3px;
  background-color: transparent;
  /* background-color: rgba(0, 0, 0, 0.3); */
  border-radius: 50%;
  display: inline-block;
}

.dot:hover {
  background-color: #184189;
  border-radius: 10px solid #000;
}

.dot {
  background-color: rgba(255, 255, 255, 1);
}
.active-dot {
  background-color: #184189;
}

.play-pause {
  color: black;
}

/* Test */

.hero-content-main-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.hero-content-wrapper {
  max-width: 1296px;
  width: 90%;
  margin: auto;
}
.hero-content {
  width: 100%;
}
