.why-us-container {
  width: 100%;
  background-color: rgba(13, 51, 101, 0.917);
  p,
  h2 {
    color: #e8e8e8;
    margin: 0;
  }
  padding: 0;
  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }

  h2 {
    margin-bottom: 30px;

    @media (max-width: 1200px) {
      font-size: 3rem;
      margin-bottom: 20px;
    }

    @media (max-width: 800px) {
      // margin-bottom: 10px;
      font-size: 2.5rem;
    }
  }
  p {
    padding-top: 3px;
    font-size: 18px;
    font-weight: 400;
    @media (max-width: 1200px) {
      font-size: 16px;
    }
  }

  .why-us-list {
    display: flex;
    align-items: center;
    margin-top: 20px;
    gap: 15px;

    @media (max-width: 1200px) {
      margin-top: 25px;
    }
  }
}

.why-us-img-container {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 5%;
  @media (max-width: 1200px) {
    margin-right: 2%;
  }

  @media (max-width: 800px) {
    width: 100%;
    justify-content: center;
    margin: 0;
  }
}
.why-us-list-cn {
  padding-bottom: 2rem;
}
.why-us-intro {
  color: #c2c2c2;
  font-weight: 500;
  padding-bottom: 1rem;
  font-size: 1.6rem;
  text-transform: uppercase;
}
.why-us-img {
  width: 100%;
  // width: 450px;
  height: 500px;
  object-fit: cover;
  object-position: center;

  @media (max-width: 800px) {
    height: 400px;
  }

  @media (max-width: 400px) {
    height: 350px;
  }
}

.why-us-content {
  width: 50%;
  margin: auto 0;
  padding-left: 2%;
  padding-right: 5%;
  @media (max-width: 800px) {
    width: 90%;
    padding: 10% 2%;
  }
  background-image: url(/Images/why-us-bg-img.jpg);
}

@media (min-width: 1600px) {
  .why-us-wrapper-pt {
    width: 90%;
  }
  .list-pt {
    width: 90%;
  }
}

.why-us-icon {
  width: 30px;
  height: 30px;
}
