/**************************************************/
/*New Product-Home Cards - Updated on Feb-2023*/
/**************************************************/
.product-header-wrap {
  display: flex;
  flex-direction: column;
  //width: 75%;
  max-width: 1296px;
  width: 90%;
  margin: auto;
  justify-content: left;
}
.product-header {
  margin-top: 5rem;
}
.prod-header {
  color: var(--color-primary);
}

.prod-text {
  padding-top: 2rem;
  width: 90%;
  color: #222;
}
@media screen and (min-width: 1400px) {
  .prod-text {
    width: 60%;
  }
}
@media screen and (min-width: 500px) and (max-width: 768px) {
  .product-header-wrap {
    width: 90%;
  }
  .prod-text {
    width: 80%;
  }
}
@media screen and (max-width: 499px) {
  .product-header-wrap {
    width: 90%;
  }
  .prod-text {
    width: 100%;
  }
}
.prod-header-gray {
  color: #777777;
}

.poduct-overview-cards {
  margin: 7rem 0;
  @media (max-width: 1100px) {
    margin: 5rem 0;
  }
}
.product-cards {
  max-width: 1296px;
  width: 90%;
  height: auto;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  @media (max-width: 480px){
    gap: 65px;
    margin-bottom: 8rem;
  }
}
@media screen and (min-width: 767px) and (max-width: 1100px) {
  .product-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 768px) {
  .product-cards {
    grid-template-columns: repeat(1, 1fr);
    //width: 90%;
  }
}
// @media screen and (min-width: 1320px) and (max-width: 1581px) {
//   .product-cards {
//     //width: 90%;
//   }
// }
// @media screen and (min-width: 1025px) and (max-width: 1319px) {
//   .product-cards {
//     //width: 85%;
//   }
// }
.prod-card-content {
  @media screen and (min-width: 1100px) {
    min-height: 155px;
  }
  @media screen and (max-width: 1100px) {
    height: 145px !important;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.prod-card-title {
  color: var(--color-primary);
  font-size: 2.2rem;
  text-transform: uppercase;
  padding: 1rem 0;
  margin: 0;
}
.prod-card-link-icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 15px;
}
.link-icon {
  display: flex;
  justify-content: flex-start;
}
.prod-card-link {
  color: var(--color-primary);
  font-size: 1.6rem;
  font-weight: 500;
  padding-left: 1rem;
  // padding-bottom: 2rem;
}
.prod-card-link-icon .fa-color {
  color: var(--color-primary);
  margin-right: 0;
}

.prod-card-img {
  border: 1px solid #f1f1f1;
  width: 100%;
}
.prod-card-img img {
  width: 98%;
  padding: 0.5rem;
  object-fit: cover;
  object-position: center;
}

.gypsum-product-card {
  width: 100%;
}
// @media screen and (min-width: 1025px) and (max-width: 1319px) {
//   .gypsum-product-card {
//     width: 90%;
//   }
// }
.prod-card-text {
  color: #222;
  font-size: 1.8rem;
}

#cn-lang {
  .prod-card-text {
    min-height: 52px;
  }
}

#pt-lang {
  .prod-card-text {
    min-height: 106px;
    @media (max-width: 1100px) {
      min-height: 66px;
    }

    @media (max-width: 768px) {
      min-height: 44px;
    }
    @media (max-width: 443px) {
      min-height: 98px;
    }
  }

  .prod-card-title {
    @media (min-width: 1100px) and (max-width: 1400px) {
      min-height: 54px;
    }
  }

  .prod-card-text-1 {
    @media (max-width: 768px) {
      min-height: 66px;
    }
    @media (max-width: 524px) {
      min-height: 98px;
    }
    @media (max-width: 350px) {
      min-height: 108px;
    }
  }
}
@media screen and (min-width: 1025px) and (max-width: 1319px) {
  .prod-card-text {
    font-size: 1.6rem;
  }
  .prod-card-title {
    font-size: 2rem;
  }
}
@media screen and (max-width: 1024px) {
  .prod-card-title {
    font-size: 1.8rem;
  }
}
