body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#cn-lang {
  font-family: 'Noto Sans SC', sans-serif;
}

#pt-lang {
  font-family: 'Open Sans', sans-serif !important;
}


*{
  margin: 0;
  padding: 0;
 /* box-sizing: border-box; */
}

:root {
  --color-primary: #083e89;
  --color-secondary: #CD151D;
  --color-accent1: #3E3E3E;
  --color-accent2: #F2F2F2;
  --color-headings: #1b0760;
  --color-body: #918ca4;
}

html{
  font-size: 62.5%;
}

a{
  text-decoration: none;
}

h1{
  font-size: 5rem;
  
    font-weight: 700;
}
h2{
  font-size: 4rem;
  /* color: #222; */
  color: var(--color-primary);
  font-weight: 600;
  /* line-height: 50px; */
}
h3{
  font-size: 2.4rem;
  font-weight: 500;
}
h4{
  font-size: 1.4rem;
}
p{
  font-size: 1.8rem;
  color: #555;
  font-weight: 400;
}
@media(max-width:  1024px){
  h1{
    font-size: 4rem;
  }
  h2{
    font-size: 3rem;
  }
  h3{
    font-size: 2.2rem;
  }
 P{
  font-size: 1.6rem;
 } 
}
@media(max-width:  768px){
  h1{
   font-size: 2.8rem;
  } 
  h2{
    font-size: 2.4rem;
  }
  h3{
    font-size: 1.8rem;
  }

 }
 @media(max-width:  480px){
  h1{
   font-size: 2.4rem;
  } 
  h2{
    font-size: 2rem;
  }
  h3{
    font-size: 1.6rem;
  }
  P{
    font-size: 1.4rem;
   }

 }
/* Horizontal Lline decoration - none*/
/* hr{
  border: none;
} */

/* Buttons on Home page */
 .btn1{
  padding: 12px 44px;
  font-size: 1.6rem;
  background: rgb(25,72,137);
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  border: none;
  border-radius: 10px solid white;
  text-align: center;

  /* Non breakable space */
  white-space: nowrap;  
}
.btn-light1{
  padding: 10px 44px;
  background: transparent;
  color: #fff;
  border: 1px solid white;
  border-radius: 10px solid white;
  text-align: center;
}
.btn1:hover{
  background: rgb(25,72,137);
background: linear-gradient(90deg, rgba(25,72,137,1) 0%, rgba(25,65,137,0.8715861344537815) 100%, rgba(0,0,0,0) 100%);
  color: #fff;
  border: 1ps solid rgb(25,72,137);
  transition: all 2s ease-in;
  border: transparent;
  text-align: center;
} 
.btn-light1:hover{
  background: rgb(25,72,137);
  background: linear-gradient(90deg, rgba(25,72,137,1) 0%, rgba(25,65,137,0.8715861344537815) 100%, rgba(0,0,0,0) 100%);
    color: #fff;
    border: 1ps solid rgb(25,72,137);
    transition: all 5s ease-in;
    border: transparent;
    text-align: center;
}