
// html {
//     /* 62.5% of 16px = 10px */
//     font-size: 62.5%;
//   }

// body{
//     margin: 0;
// }
.company-mission-intro{
    display: flex;
    justify-content: center;
    height: 100%;
    overflow: hidden;
    align-items: center;
    background-color: #eef0f2;
    margin: 0;
    padding: 0;
}
.mission-intro-container{
    display: flex;
    gap: 20px;
    @media screen and (min-width: 768px) and (max-width: 1024px){
        flex-direction: column; 
    }
    @media screen and (min-width: 481px) and (max-width:767px){
        flex-direction: column; 
    }
    @media screen and (max-width: 480px){
        flex-direction: column; 
    }
    max-width: 1296px;
    width: 90%;
    // height: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 10%;
    margin-bottom: 10%;
    //margin-right: 5%;
    //margin-left: 5%;
}
    .mission-intro-container2{
        display: flex;
        
        @media screen and (min-width: 768px) and (max-width: 1024px){
            flex-direction: column-reverse; 
        }
        @media screen and (min-width: 481px) and (max-width:767px){
            flex-direction: column-reverse; 
        }
        @media screen and (max-width: 480px){
            flex-direction: column-reverse; 
        }
    
        width: 100%;
        // height: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
        margin-bottom: 10%;
        margin-right: 5%;
        margin-left: 5%;
        gap: 5%;

    }
    .col-1{
        width: 50%;
        @media screen and (min-width: 1025px) and (max-width: 1300px){
            width: 50%;
            
        }
        @media screen and (min-width: 768px) and (max-width: 1024px) {
            width: 100%;
        }
        @media screen and (min-width: 481px) and (max-width:767px){
            width: 100%;
        }
        @media screen and (max-width: 480px){
            width: 100%;
            padding-right:0.5rem;
        }
    }
    .col-2{
        width: 50%;
        @media screen and (min-width: 1299px) and (max-width: 1500px){
            width: 50%;
            padding-right:1.5rem;
            padding-left:5rem;
        }
        @media screen and (min-width: 1025px) and (max-width: 1300px){
            width: 50%;
        }
        @media screen and (min-width: 768px) and (max-width: 1024px){
            width: 100%;
            padding-left:0;
        }
        @media screen and (min-width: 481px) and (max-width:767px){
            width: 100%;
            padding-left:0;
        }
        @media screen and (max-width: 480px){
            width: 100%;
            padding-right:0.5rem;
            padding-left:0;
        }
        padding-left:3rem;
        padding-top: 5rem;
        @media  (max-width:1024px){
            padding-top: 0;
        }
    }
    .col-2-1{
        padding: 6rem;
        @media screen and (min-width: 1299px) and (max-width: 1500px){
            width: 50%;
            padding-right:0;
            padding-top: 8rem;
        }
        @media screen and (min-width: 1025px) and (max-width: 1300px){
            width: 50%;
            padding-right: 0;
            padding-top: 8rem;
        }
        @media screen and (min-width: 768px) and (max-width: 1024px){
            width: 100%;
            padding-left:0;
            padding-right: 0;
        }
        @media screen and (min-width: 481px) and (max-width:767px){
            width: 100%;
            padding-left:0;
            padding-right: 0;
        }
        @media screen and (max-width: 480px){
            width: 100%;
            padding-right: 0;
            padding-left:0;
        }
        padding-top: 5rem;
    }
    .col-2-2{
        padding: 6rem;
        @media screen and (min-width: 1299px) and (max-width: 1500px){
            width: 50%;
            padding-right:1.5rem;
            padding-left:0;
        }
        @media screen and (min-width: 1025px) and (max-width: 1300px){
            width: 50%;
            padding-left:0;
        }
        @media screen and (min-width: 768px) and (max-width: 1024px){
            width: 100%;
            padding-left:0;
            padding-right: 0;
        }
        @media screen and (min-width: 481px) and (max-width:767px){
            width: 100%;
            padding-left:0;
            padding-right: 0;
        }
        @media screen and (max-width: 480px){
            width: 100%;
            padding-right: 0;
            padding-left:0;
        }
        padding-top: 5rem;
    }

    hr.mission-intro-line {
        width: 10%;
        height: 3px;
        background-color: #CD151D;
        border: none;
        margin: 0;
        opacity: 1;
    }
    .mission-intro-header{
        color: rgb(31, 29, 29);
        font-size: 1.6rem;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 2rem;
    }
    .mission-intro-text {
        font-size: 4.4rem;
        @media screen and (min-width: 1299px) and (max-width: 1500px){
            font-size: 4rem;
            line-height: 4.2rem;
        }
        @media screen and (min-width: 481px) and (max-width:767px){
            font-size: 3rem;
            line-height: 3.5rem;
        }
        @media screen and (max-width: 480px){
            font-size: 2.4rem;
            line-height: 3rem;
        }
        color: black;
        line-height: 5rem;
        font-weight: 300;
        margin: 3rem 0;
    }
    .col-2 p{
        font-size: 2rem;
        @media screen and (min-width: 1299px) and (max-width: 1500px){
            font-size: 1.9rem;
        }
        @media screen and (min-width: 481px) and (max-width:767px){
            font-size: 1.8rem;
        }
        @media screen and (max-width: 480px){
            font-size: 1.8rem;
            line-height: 3rem;
        }
        line-height: 3rem;
        color: #292828;
        font-weight: 400;
    
    }
   