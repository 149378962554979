/* Sustainablility Page Update - Feb-2023*/
.sustainability-container{
    width: 100%;
    margin-bottom: 7%;
    margin-top: 3%;
}

.sustain-title{
    max-width: 1296px;
    width: 90%;
    margin: auto;
    padding: 5rem 0;
}
// .sustain-title h2{
//     width:70%;
// }
.sustain-title-gray{
    color: #777;
}
.sustain-wrapper{
    display: flex;
    max-width: 1296px;
    width: 90%;
    margin: auto;
    gap: 50px;
    padding: 3rem 0;
}

.sustain-highlight{
    width: 20%;
    display: flex;
    flex-direction: column;
}

.sustain-highlight-icon .fa-color{
  color: #8DC63F;
  padding-left: 1rem;
  margin: 0;
}
.sustain-one{
    margin-bottom: 1rem;
    display: flex;   
    align-items: center;
}
.sustain-one h4{
font-weight: 500;
font-size: 18px;
}
.sustain-highlight-icon{
    padding-right: 1rem;
} 

.sustain-image{
    width: 45%;
    height: 100%;
    border-radius: 10px;
}
.sustain-image img{
    width: 100%;
    border-radius: 10px;
}

.sustain-content{
    width:40%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* margin-left: 5rem; */
}

.sustain-intro{
    padding-bottom: 2rem;
    width: 100%;
    color: #000;
    font-size: 2.2rem;
}

.sustain-intro-text p{
    color: #555;
    width: 100%;
}
// @media screen and (min-width: 1600px){
//     .sustain-wrapper{
//         width: 75%;
//     }
// }


@media screen and (min-width:1200px) and (max-width: 1460px){
    .sustain-highlight{
        width: 17%;
    }
    
    .sustain-image{
        width: 40%;
    }
    .sustain-content{
        width: 38%;
    }
    .sustain-wrapper{
        width: 90%;
        gap: 25px;
    }
}
@media screen and (min-width: 1025px) and (max-width: 1199px){
    
    /* .sustain-wrapper{
        flex-direction: column;
        padding: 0;
    } */
    .sustain-highlight{
        width: 20%;
    }
    
    .sustain-image{
        width: 35%;
    }
    .sustain-content{
        width: 40%;
    }
    .sustain-wrapper{
       // width: 95%;
        gap:25px;
    } 
    .sustain-intro{
        font-size: 2rem;
    }
    .sustain-intro-text p{
        font-size: 1.6rem;
    }
}
@media screen and (min-width: 1071px){
    .sustain-highlight-cn{
        width: 15%;
    }
    /* .sustain-highlight-icon{
        padding-right: 0;
    } */
}
@media screen and (min-width: 1025px) and (max-width: 1070px){
    .sustain-highlight-cn{
        width: 15%;
    }
}
@media screen and (max-width: 1024px){
    
    .sustain-title h2{
        width: 100%;
    }
    .sustain-wrapper{
        flex-direction: column;
        padding: 0;
    }
    .sustain-highlight{
        flex-direction: column;
        width: 95%;
        gap: 20px;

    }
    .sustain-image{
        width: 80%;
    }
    .sustain-content{
        width: 95%;
    }
    .sustain-wrapper{
        //width: 80%;
        gap: 30px;
    }
}
@media screen and (min-width: 500px)and (max-width: 768px){
    .sustain-wrapper{
        width: 90%;
    }
    .sustain-image{
        width: 100%;
    }
    .sustain-content{
        width: 100%;
    }
    .sustain-title{
        width: 90%;
    }
    
}

@media screen and (min-width: 700px) and (max-width: 1024px){
    .sustain-highlight{
            flex-direction: row;
            width: 95%;
            gap: 20px;
    
        }
    }
    @media screen and (min-width: 600px) and (max-width: 1024px){
    .sustain-highlight-cn{
        flex-direction: row;
        width: 95%;
        gap: 60px;

    }
}
@media screen and (max-width: 599px){
    .sustain-highlight{
        justify-content: center;
        margin: auto;
        width: 100%;
    }
    .sustain-title{
        padding-bottom: 0;
        padding-top: 3rem;
    }
    .sustain-wrapper{
        width: 90%;
        padding: 2rem 0;
    }
    .sustain-image{
        width: 100%;
    }
    .sustain-content{
        width: 100%;
    }
    .sustain-one{
        margin-bottom: 0;

    }
}
@media screen and (max-width: 599px){
    .sustain-highlight-cn .sustain-one{
        margin-bottom: 0;

    }
}
.sustain-svg{
    width: 32px;
    height: 32px;
}

