.sales-home {
    background-color: rgb(238, 240, 242);
      width: 100%;
      /* padding: 3% 0; */
         background-image: linear-gradient(to right, rgb(185, 185, 185), rgb(225, 225, 225) );
    }
    .sales-home-container {
      display: flex;
      gap: 30px;
      align-items: center;
      padding: 5% 4%;
      max-width: 1296px;
      width: 90%;
      margin: auto;
    }
  .sales-intro-title{
    color: #043273;
    font-weight: 600;
    padding-bottom: 1.5rem;
    font-size: 1.8rem;
    text-transform: uppercase;
  }
  .sales-intro-text{
    font-weight: 400;
    font-size: 2rem;
    color: #000;
    width: 80%;
  }
    .sales-home-text-container{
      border-right: 1px solid #e1e1e1;
    }
    .sales-home-btn{
      width: 30%;
      height: 100%;
      box-sizing: border-box;
      padding-left: 2rem;
      display: flex;
      justify-content: flex-end;
    }
    .sales-home-inner-text{
      padding: 0 2rem;
    }
  .sales-home-inner-text p{
    padding-top: 2rem ;
  }
  @media screen and (min-width: 801px)and (max-width : 1200px){
    .sales-home-container{
      padding: 5% 2%;
      width: 90%;
    }
    .sales-intro-text{
      width: 95%;
  
    }
  }
  @media screen and (max-width : 800px){
    .sales-home-container{
      flex-direction: column;
      width: 90%;
      padding: 12% 1rem;
    }
    .sales-home-text{
      text-align: center;
    }
    .sales-intro-text{
      width: 100%;
      padding-bottom: 2rem;
    }
    .sales-home-btn{
       display: flex;
       justify-content: center;
    }
    .sales-home-text-container{
      border-right: none;
      border-bottom: 1px solid #e1e1e1;
    }
  }
  @media screen and (max-width : 500px){
    .sales-intro-text{
       font-size: 2rem;
    }
  }