.footer-1st-row {
  display: flex;
  justify-content: center;
  max-width: 1296px;
  width: 90%;
  margin: auto;
  background-color: #081e3b;
  position: relative;
  border-top: 1px solid rgba(125, 125, 125, 0.2);
  padding-top: 1srem;
}

.footer-address {
  width: 95vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 5vw;
  @media screen and (min-width: 1025) and (max-width: 1460px) {
    column-gap: 2vw;
  }
  @media screen and (max-width: 1024px) {
    justify-content: center;
    //width: 100%;
  }
  justify-content: center;
  width: 100%;
  color: #c2c2c2;
  font-weight: 400;
  padding: 0.5rem 0;
}

.footer-address-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // @media (max-width: 500px) {
  //   justify-content: flex-start;
  // }
  width: 100%;
  font-size: 1.6rem;
  @media (max-width: 500px) {
    font-size: 1.4rem;
  }

  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.footer-address-icon {
  width: 2.2rem;
  margin-right: 2rem;
}
.footer-address-location {
  height: 2rem;
}

.footer-2nd-row {
  background-color: #081e3b;
}

.footer-nav {
  display: flex;
  //flex-wrap: wrap; // to put the logo and nav items in column while they are in the same flex box
  max-width: 1296px;
  width: 90%;
  margin: auto;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 20px;
  font-size: 1.6rem;
  gap: 2rem;
  @media screen and (max-width: 500px) {
    flex-wrap: wrap; // to put the logo and nav items in column while they are in the same flex box
    justify-content: flex-start;
    gap: 10px;
  }
}
.footer-navitem-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;
  @media (max-width: 500px) {
    gap: 20px;
    row-gap: 7px;
  }
  row-gap: 7px;
}
.footer-nav .footer-item {
  color: #c7c7c7;
  font-size: 1.8rem;
  @media screen and (max-width: 1025px) {
    font-size: 1.6rem;
  }
  @media (max-width: 500px) {
    font-size: 1.4rem;
  }
  font-weight: 400;
  width: 100%;
  white-space: nowrap;
  margin: 0;
}
.footer-nav-col .footer-item::before{
  content: "|";
  font-weight:300;
  font-size: 16px;
  left: 0;
  color: #bdbdbd;
  display: inline-block;
  padding-right: 12px;
}
.footer-nav-col  .link-1::before{
  content: none;
}

//  .footer-item:hover {
//   color: #fff;
//   font-size: 1.8rem;
// }
.footer-nav-col p:hover {
  color: white;
}
.footer-nav-col img {
  height: 4rem;
  @media screen and (max-width: 1025px) {
    height: 3rem;
  }
  object-fit: cover;
  cursor: pointer;
}
.footer-nav-col {
  cursor: pointer;
  //width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-nav-logo {
  display: flex;
  justify-content: flex-start;
  width: 20%;
  //flex-direction: column;
  // @media screen and (max-width: 600px) {
  //   width: 100%;
  // }
}

.footer-social {
  display: flex;
  column-gap: 1vw;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.footer-social-col img {
  height: 2rem;
  object-fit: cover;
}
.footer-social-twitter img {
  height: 1.5rem;
}

.footer-copyright {
  display: flex;
  column-gap: 0.5vw;
  max-width: 1296px;
  width: 90%;
  margin: auto;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-size: 1.4rem;
  padding-bottom: 2rem;
  color: #929292;
  font-weight: 400;
}

.footer-bar {
  padding-right: 0.5rem;
}

.footer-item {
  white-space: nowrap;
}
