.banner-common {
  width: 100%;
  height: 400px;
  @media (max-width: 1024px) {
    height: 300px;
  }
  @media (max-width: 600px) {
    height: 250px;
  }
  @media (max-width: 400px) {
    height: 200px;
  }
  position: relative;
  //background-image: url(../../assets/AboutBanner.png);
  background-position: center;
  background-size: cover;
  position: relative;
  margin-top: 63px;



}
.banner-overlay{
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.8015581232492998) 0%,
    rgba(0, 0, 0, 0.60) 10%,
    rgba(0, 0, 0, 0.5455357142857143) 25%,
    rgba(0, 0, 0, 0.4766281512605042) 45%,
    rgba(0, 0, 0, 0) 100%
  );
}

.banner-common .banner-title {
  position: absolute;
  transform: translate(0%, -50%);
  top: 45%;
  left: 0;
  text-align: start;
  z-index: 10;
  // margin-left: 10%;
  font-weight: bold;
  color: white;
  z-index: 1;

  width: 100%;
  display: flex;
  justify-content: center;
}

.banner-title-span {
  max-width: 1296px;
  width: 90%;
  align-self: center;
  text-transform: uppercase;
}