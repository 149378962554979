.gypsum-product-steel-sec-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: fit-content;
  margin-top: 5%;
  @media(max-width:769px){
    margin-top: 7%;
  }
  margin-bottom: 8%;
}

.gypsum-product-steel-sec {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 60px;
  row-gap: 90px;
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 2fr);
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 3fr);
  }
  max-width: 1296px;
  width: 90%;
  margin: auto;

  // margin: 5% 0;
  //gap: 5%;
  // @media (max-width: 650px) {
  //     flex-direction: column;
  //     align-items: center;
  // }
}

.gypsum-product-steel {
  display: flex;

  //width: 90%;
//   @media (max-width: 500px) {
//     justify-content: center;
//   }
  > div {
    display: flex;
    flex-direction: column-reverse;
    //gap: 5%;
    > div {
      width: 100%;
    }
  }
}
// .gypsum-product-steel-right {
//   justify-content: flex-end;
// }

.gypsum-product-steel {
  .gypsum-product-detail-content{
    padding-top: 20px;
    padding-left: 0;
}
}

.gypsum-product-steel-sec {
  padding-top: 3%;
  @media (max-width: 769px) {
    padding-bottom: 10%;
  }
}

.gypsum-product-steel-img-container {
    display: flex;
    justify-content: flex-start!important;
}
.gypsum-product-detail-img-container{
    justify-content: flex-start!important;
    margin-top: 0;
}
.light-steel-img {
  width: 90%;
  height: 90%;
  @media (max-width: 500px) {
    width: 100%;
    height: 100%;
  }
  max-width: 400px;
  object-fit: cover;

  @media (max-width: 1024px) {
    // max-height: 400px;
    max-width: 900px;
  }
  @media (max-width: 767px) {
    //   max-height: 350px;
  }
}

.gypsum-product-steel {
  h2 {
    font-size: 3rem;
    @media (max-width:769px) {
      font-size: 2.4rem;
    }
  }
}
