
.home-about{
    display: flex;
    justify-content: center;
   
    align-items: center;
    padding-top: 7% ;
    padding-bottom: 10%;
    background-color: #f6f6f6;
}

.home-about-container{
    display: flex;
    max-width: 1296px;
    width: 90%;
    @media (min-width:1024px) and (max-width: 1199px){
        width: 90%;
        justify-content: center;
    }
    @media (min-width:769px) and (max-width: 1024px){
        width: 80%;
    }
    
    @media  (max-width: 1000px){
        flex-direction: column;
        width: 90%;
        gap: 50px;
    }
}
.home-about-content{
    width: 50%;
    @media (min-width:769px) and (max-width: 1024px){
        width: 80%;
        margin: auto;
    }
    @media (max-width: 768px){
        width:100%;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.home-about-title{
    width:90%;
    padding-bottom: 2rem;
    @media (min-width:801px) and (max-width: 1023px){
        font-size: 3.2rem!important;
    }
    @media (max-width: 768px){
        width:100%;
    }
    @media (min-width:769px) and (max-width: 1024px){
        width: 100%;
    }
}

.home-about-title-gray{
    color: #777;
}
.home-about-text{
    width: 90%;
    @media (max-width: 768px){
        width:100%;
    }
    @media (min-width:769px) and (max-width: 1024px){
        width: 100%;
    }
    padding-bottom: 2rem;
    @media (max-width: 1000px) {
        padding-bottom: 30px;
    }
    color: #222;
}
.home-about-image{
   width: 50%;
   @media (min-width:769px) and (max-width: 1024px){
    width: 80%;
    margin: auto;
}
@media (max-width: 768px){
    width:100%;
}
   display: flex;
   justify-content: flex-end;
  
}
.home-about-image img{
    width:90%; 
    @media  (min-width:1200px) and (max-width:1400px){
        width: 100%;
    }
    @media (min-width:1024px) and (max-width: 1199px){
        width: 95%;
    }
    @media  (max-width: 1000px){
        width: 100%;
    }
    @media (min-width:801px) and (max-width: 1023px){
        width: 100%;
    }
    border-radius: 5px ;
}

.home-about-btn button{
    color: white;
}
.home-about-btn{
    width: 30%;
    @media (min-width: 801px) and (max-width: 950px){
        width: 40%;
    }
    @media (max-width: 580px){
        width: 40%;
    }
    @media (max-width: 360px){
        width: 50%;
    }
}