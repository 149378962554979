.company-profile-banner {
  background: url("../../../Images/AboutBannerImg-New.png");
}
.company-banner-headline::after {
  background: #000;
  opacity: 1;
}

/*------------------------------------------*/

/* About Us Updated Vesion - Feb-2023*/

.about-banner-new {
  margin-top: 63px;
  padding: 10rem 0;
  width: 100%;
  height: auto;
  position: relative;
  background-color: #0d3365;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1100px) {
  .about-banner-new {
    //margin-top: 70px;
    padding-top: 5rem;
    padding-bottom: 1rem;
  }
}
@media screen and (max-width: 549px) {
  .about-banner-new {
    padding-bottom: 7rem;
  }
}

.about-banner-container {
  display: flex;
  justify-content: center;
  max-width: 1296px;
  width: 90%;
  gap: 50px;
}

@media screen and (min-width: 550px) and (max-width: 1100px) {
  .about-banner-container {
    flex-direction: column;
  }
}
@media screen and (max-width: 549px) {
  .about-banner-container {
    flex-direction: column;
  }
}
.about-intro {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .hr-line{
    width: 75px;
  height: 3px;
  border: none;
  color: red;
  opacity: 1;
  }
}
@media screen and (max-width: 1100px) {
  .about-intro {
    width: 100%;
  }
}
.about-banner-img {
  width: 50%;
}
@media screen and (max-width: 1100px) {
  .about-banner-img {
    width: 100%;
  }
}

.about-banner-img img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}
@media screen and (min-width: 1101px) and (max-width: 1400px) {
  .about-banner-img img {
    height: 100%;
    border-radius: 10px;
  }
}
@media screen and (min-width: 550px) and (max-width: 1100px) {
  .about-banner-img img {
    height: 80%;
    border-radius: 10px;
  }
}
.about-intro-title {
  color: #c2c6dd;
  /* padding-bottom: 0.5rem; */
  font-size: 2rem;
}
.hr-line hr {
  width: 75px;
  height: 3px;
  border: none;
  background-color: red;
  opacity: 1;
  margin-top: 5px;
}
.about-title h1 {
  color: white;
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 2rem; 
  line-height: 1;
}
/* @media screen and (min-width: 768px)and (max-width: 1024px) {
  .about-title h2{
    font-size: 3.4rem;
  }
} */
.about-text-new p {
  color: #d2d2d2;
  width: 100%;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .about-text-new p {
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 1101px) and (max-width: 1400px) {
  .about-text-new p {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 767px) {
  .about-text-new p {
    font-size: 1.6rem;
  }
}
