/* Cumpany Culture Section*/

.about-company-culture {
  padding-bottom: 7%;
  padding-top: 3rem;
  display: flex;
  justify-content: center;
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .about-company-culture {
    padding-top: 0;
  }
}
.about-company-culture-container {
  margin: 5rem 0;
  display: flex;
  gap: 5rem;
  font-size: 2.2rem;
  color: var(--color-accent1);
  justify-content: center;
  align-items: center;
  max-width: 1296px;
  width: 90%;
  padding: 0;
}
@media screen and (max-width: 1024px) {
  .about-company-culture-container {
    flex-direction: column;
  }
}

.culture-img {
  width: 40%;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  flex-grow: 1;
}
@media screen and (min-width: 1025px) and (max-width: 1199px) {
  .culture-img {
    width: 50%;
  }
}
.culture-img img {
  width: 100%;
}
.culture-content {
  width: 70%;
  flex-grow: 1;
}
.culture-content p {
  padding-top: 2%;
}

.culture-content h2 {
  line-height: 1.2;
}
@media screen and (max-width: 1024px) {
  .culture-img {
    width: 100%;
  }
  .culture-content {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  /* .culture-content{
      padding-left: 1rem;
    } */
  .culture-content h2 {
    font-size: 2.5rem;
  }

  .culture-content p {
    font-size: 1.6rem;
  }

  /* .about-img {
      height: 32rem;
      flex-grow: 1;
    } */
}
@media screen and (max-width: 1024px) {
  .culture-img {
    width: 100%;
    flex-grow: 1;
  }
}

.culture-header-gray {
  color: #777777;
}
.culture-text {
  color: #222;
}
