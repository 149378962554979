.vision-about {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #000;
  background-image: url(../../../Images/MissionVisionBg3.jpg);
  background-size: cover;
  background-position: center;
}
.vision-card-contanier {
  /* display: flex; */
  max-width: 1296px;
  width: 90%;
  gap: 40px;
  justify-content: space-between;
  align-items: center;
  padding: 10rem 0;
  @media screen and (max-width: 1025px) {
    padding: 5rem 0;
  }
}
@media screen and (max-width: 768px) {
  .vision-card-contanier {
    width: 90%;
  }
}

.vision-card-wrapper {
  width: 100%;
  display: flex;
  max-width: 1296px;
  gap: 100px;
  justify-content: space-between;
}
.vision-card-wrapper .fa-color {
  color: #c2c2c2;
}

@media screen and (max-width: 1025px) {
  .vision-card-wrapper {
    flex-direction: column;
    gap: 50px;
  }
}

.vision-card {
  display: flex;
  flex-direction: column;
  background: transparent;
  width: 40%;
}
.mission-card {
  display: flex;
  flex-direction: column;
  background: transparent;
  width: 40%;
}
@media screen and (max-width: 1025px) {
  .vision-card {
    width: 100%;
  }
}

@media screen and (max-width: 1025px) {
  .mission-card {
    width: 100%;
  }
}

@media (min-width: 1026px) and (max-width: 1450px) {
  .vision-card-contanier {
    width: 90%;
  }
  .vision-card {
    width: 50%;
  }
  .vision-card-wrapper {
    gap: 60px;
  }
}
@media (min-width: 1026px) and (max-width: 1450px) {
  .mission-card {
    width: 50%;
  }
  .vision-card-wrapper {
    gap: 60px;
  }
}

.mission-card img {
  width: 70px;
  height: 70px;
  margin-bottom: 20px;
}

.vision-card img {
  width: 62px;
  height: 60px;
  margin-bottom: 20px;
}

.vision-card p {
  color: #c2c2c2;
  padding-top: 2rem;
  //font-weight: 300;
  width: 100%;
}
.mission-card h3 {
  color: white;
}
.vision-card h3 {
  color: white;
}
.mission-card p {
  color: #c2c2c2;
  padding-top: 1rem;
  width: 100%;
}

/* @media screen and (min-width: 481px) and (max-width: 1025px) {
    .vision-card h3 {
      font-size: 3.5rem;
    }
  
    .vision-card p {
      font-size: 1.8rem;
      width: 100%;
    }
  } */
