.navbar {
  width: 100vw;
  top: 0;
  background-color: #fefefe;
  height: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: relative;
  position: fixed;
  box-shadow: 0px 2px 3px rgba(109, 108, 108, 0.1);
}

.navbar--container {
  max-width: 1296px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo--wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 135px;
  height: 40px;
  @media (max-width: 1024px) {
    width: 130px;
    height: 40px;
  }

  @media (max-width: 480px) {
    width: 110px !important;
    height: 30px;
  }
  > * {
    width: 100%;
  }
}

.navbar--logo {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.navbar--items {
  min-width: 533px;
  display: flex;
}

.navbar--item {
  @media (max-width: 1024px) {
    border-bottom: 1px solid rgba(166, 170, 171, 0.1);
  }
}

.navbar--item:last-child {
  @media (max-width: 1024px) {
    border-bottom: 0;
  }
}

.navbar--item,
.navbar--lang {
  padding: 0 20px;
  font-size: 13px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (max-width: 1024px) {
    padding: 0;

  }
}

.navbar--chevron {
  padding-left: 5px;
  display: flex;
> * {
    width: 12px;
    height: 12px;
    @media (max-width: 450px) {
        width: 10px;
    height: 10px;
    }
}

}

.navbar-item-container--lang {
  display: flex;
//   width: 116px;
  margin-right: 20px;
  @media (max-width: 350px) {
    margin-right: 5px;
  }
}

.navbar--lang {
  max-width: 233px;

  display: flex;
  align-items: center;

    width: 86px;

  @media (max-width: 450px) {
    font-size: 10px;
  }

}

.navbar-lang--bar {
  border-left: 1px solid #a6aaab;
  @media (max-width: 1024px) {
    border: 0;
  }
  width: 1px;
  height: 40px;
  margin-right: 10px;
}

.navbar-item--dropdown {
  @media (min-width: 1025px) {
    position: absolute;
    top: 41px;
  }
  max-height: 0;
  padding-right: 20px;
  @media (min-width: 1025px) {
    background-color: #fefefe;
    

  }
  z-index: 10;
  overflow: hidden;
  transition: max-height 0.15s ease-out;
  cursor: pointer;
}
#cn-lang {
    .navbar-item--dropdown {
        @media (min-width: 1025px) {
            min-width: 110px;
        }
    }
}
#pt-lang {
    .navbar-item--dropdown {
        @media (min-width: 1025px) {
            min-width: 165px;
        }
    }
}

.navbar-item-dropdown--lang {
    position: absolute;
    top: 41px;
    @media (min-width: 1025px) {
        margin-left: 5px;
    }
   @media (max-width: 1024px) {
    .navbar-item--list { 
        padding: 0 10px !important;
      }
   }
    max-height: 0;
    background-color: #fefefe;
    z-index: 10;
    overflow: hidden;
    transition: max-height 0.15s ease-out;
    cursor: pointer;
    width: 109px;
    @media (max-width: 1024px) {
      width: 103px;
      
    }
   
  }

  #pt-lang {
    .navbar-item-dropdown--lang { 
        width: 116px;
    }
  }
.navbar-item-dropdown--show {
  max-height: 300px;
}

.navbar-item--list {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 13px;
  padding-left: 20px;
  border-bottom: 1px solid rgba(166, 170, 171, 0.3);
  // font-family: "Roboto", sans-serif;
  // font-weight: 500;
}


#pt-lang {
  .navbar--items{
    .navbar-item--list,
    .navbar--item {
      font-family: "Roboto", sans-serif;
      font-weight: 500;
    }
  }
}
.navbar-items--show {
  .navbar-item--list {
    border-bottom: 1px solid rgba(166, 170, 171, 0.3);
    @media (max-width: 1024px) {
      border-bottom: 1px solid rgba(166, 170, 171, 0.1);
      margin-left: 20px;
      padding-left: 0;
    }
  }
}

// .navbar-item--list:last-child {
//   // border: 0;
// }

.navbar-item--list:hover {
  color: #cd151d !important;
}

.navbar-item--list:hover,
.navbar--item:hover,
.navbar--lang:hover {
  > * {
    color: #cd151d !important;
  }
  color: #cd151d !important;

  @media (max-width: 1024px) {
    > * {
      color: #b8b5b5 !important;
    }
    color: #b8b5b5 !important;
  }
}

.navbar--menu {
  @media (min-width: 1025px) {
    display: none;
  }
  cursor: pointer;
  @media (max-width: 1024px) {
    display: flex;
    position: relative;
    width: 30px;
    height: 40px;
    align-items: center;
    justify-content: center;

    div {
        width: 20px;
        height: 20px;
       > * {
        width: 20px;
        height: 20px;
        color: #222;
       }
    }

    // .navbar-menu--close {
    //     > * {
    //         width: 40px;
    //         height: 40px;
    //     }
    // }
  }


}

.navbar-item--container {
  display: flex;
  flex-direction: column;

  
}


.navbar-item--container-wrapper {
  display: flex;
  align-items: center;
  z-index: 133;
  justify-content: space-between;

}

.navbar--items {
  @media (max-width: 1024px) {
    display: none;
  }
}

.navbar-items--show {
  @media (max-width: 1024px) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    
    z-index: 12;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 0;
    padding-top: 63px;
    > * {
      color: #fff;
    }
    .navbar--item,
    .navbar-item--container {
      width: 100%;
      padding-left: 10%;
      min-height: 60px;
      background-color: #194189;
    }

    .navbar-item--container {
      .navbar--item {
        padding: 0;
        display: flex;
      }
    }

    .navbar-item-dropdown--show {
      > * {
        color: #fff;
        background-color: #194189;
      }

      .navbar-item--list {
        min-height: 60px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .navbar--items:first-child {
    padding-top: 63px;
    margin-top: -63px;
  }
}

@media (min-width: 1025px) {
  .navbar-item-list-1st {
    padding-top: 20px !important;
  }
}

.navbar-item-container--lang .navbar-item-dropdown--show {
  .navbar-item-list-1st {
    padding-top: 20px !important;
  }
}

.navbar--item,
.navbar-item--list {
  @media (max-width: 1024px) {
    > * {
      color: #fff;
    }
  }
  @media (min-width: 1025px) {
    > * {
      color: #222;
    }
  }
}

.active {
  color: #cd151d !important;

  @media (max-width: 1024px) {
    color: #b8b5b5 !important;
  }
}

.navbar-item--list,
.navbar--item {
  text-transform: uppercase !important;
  > * {
    text-transform: uppercase !important;
  }
}

.navbar--item,
.navbar-item--dropdown {
  > * {
    color: #222;
    @media (max-width: 1024px) {
      color: #fff;
    }
  }
}

@media (max-width: 1024px) {
    .navbar-item--container {
        .navbar--item {
            width: 100%;
          }
        }
}
