.gypsum-banner-container {
  margin-top: 63px;
  width: 100%;
  background-color: #0d3365;
  display: flex;
  justify-content: center;
  // align-items: center;
}

.gypsum-banner {
  max-width: 1296px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5% 0;
  @media (max-width: 768px) {
    padding: 15% 0;
  }
  @media (max-width: 580px) {
    flex-direction: column;
    align-items: end;
  }
}

.gyspum-banner-hr {
  width: 75px;
  height: 3px !important;
  background-color: #cd151d;
  opacity: 1;
  border: none;
  margin-top: 10px;
}

.gypsum-banner-content {
  width: 50%;
  @media (max-width: 767px) {
    width: 95%;
  }

  h1 {
    color: whitesmoke;
    margin-bottom: 30px;
    margin-top: 20px;
    text-transform: uppercase;
  }
}

.gypsum-banner-text,
.gypsum-banner-list,
.gypsum-banner-list.li {
  color: #d2d2d2;
  font-size: 2.2rem;
  font-weight: 300;
  margin: 0;
  @media (max-width: 1300px) {
    font-size: 1.8rem;
  }

  @media (max-width: 1025px) {
    font-size: 1.6rem;
  }
}

.gypsum-banner-list {
  li {
    list-style-type: disc;
    margin-bottom: 5px;
  }
  margin-left: 40px;
  @media (max-width: 767px) {
    margin-left: 20px;
  }

  @media (max-width: 580px) {
    margin-left: 10px;
  }
  margin-bottom: 10%;
}

.gypsum-banner-img {
  max-width: 500px;
  max-height: 400px;
  object-fit: contain;

  @media (max-width: 1200px) {
    width: 400px;
    height: 400px;
  }

  @media (max-width: 950px) {
    width: 300px;
    height: 300px;
  }

  @media (max-width: 767px) {
    width: 220px;
    height: 220px;
  }

  @media (max-width: 580px) {
    width: 300px;
    height: 300px;
  }
}

.gypsum-product-sec {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5% 0;
  // @media (max-width: 768px) {
  //   padding: 10% 0;
  // }
}

.gypsum-product-sec:last-child {
  padding-bottom: 10%;
  @media (max-width: 768px) {
    padding-bottom: 15%;
  }
  @media (max-width: 480px) {
    padding-bottom: 20%;
  }
}
.gypsum-product-sec-1{
  @media (max-width: 768px) {
    padding-top: 5% !important;
  }
  @media (max-width: 480px) {
    padding-top:10% !important;
  }
}
.gypsum-product-detail {
  max-width: 1296px;
  width: 90%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    width: 80%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10%;
    row-gap: 30px;
  }

  @media (max-width: 767px) {
    padding: 0;
    width: 90%;
  }

  .active,
  .dot1:hover,
  .dot2:hover,
  .dot3:hover,
  .dot4:hover,
  .dot5:hover {
    background-color: #717171;
  }
}

.gypsum-product-detail-content {
  width: 50%;
  height: 100%;
  padding-right: 5%;
  @media (max-width: 1024px) {
    width: 100%;
    padding-right: 0;
  }

  height: 100%;

  h2 {
    color: #083e89;
    margin-bottom: 10px;
  }

  h4 {
    //font-size: 2rem;
    font-weight: 700;
    color: #083e89;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  h4:first-of-type {
    margin-top: 30px;
  }

  // h4 {
  //   @media(max-width: 1200px) {
  //     font-size: 1.8rem;
  //   }

  // }

  a {
    font-weight: 300;
    font-size: 1.6rem;
    color: black;
    text-decoration: underline;
  }

  p {
    margin: 2px 0;
    font-weight: 400;
    font-size: 1.6rem;
    @media (max-width: 1025px) {
      font-size: 1.4rem;
    }
    color: #222;

    span {
      font-weight: 600;
      color: #000;
    }
  }
}

.pdf-link {
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  padding-bottom: 5px;
}

.gypsum-product-detail-img-container {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1024px) {
    width: 100%;
    margin-top: 5%;
    justify-content: flex-start;
  }
}

.mySlides-img {
  width: 100%;
  height: 100%;
  max-height: 500px;
  object-fit: cover;
  @media (max-width: 1024px) {
    max-height: 400px;
    max-width: 900px;
  }
  @media (max-width: 767px) {
    max-height: 350px;
  }
}

.mySlides-img1 {
  height: 100%;
  width: auto;
}

.mySlides-img2 {
  height: 500px;
  width: 500px;
}

.light-steel-img {
  height: auto;
  width: 400px;

  @media (max-width: 1024px) {
    width: 300px;
  }

  @media (max-width: 600px) {
    width: 300px;
  }
}

.gypsum-banner-img1 {
  width: auto;
  height: 400px;
  @media (max-width: 1024px) {
    height: 300px;
  }

  @media (max-width: 767px) {
    padding-right: 10%;
  }
}

.gypsum-banner-img2 {
  width: 400px;
  height: auto;
  @media (max-width: 1200px) {
    width: 300px;
  }

  @media (max-width: 1100px) {
    width: 250px;
  }
}

.product-doc--link {
  display: flex;
  align-items: center;
}

.pdf--icon {
  > * {
    width: 24px;
    height: 24px;
    padding-right: 10px;
  }
}
